<template>
  <div>
    <form @submit.prevent="submit">
      <div class="text-center">
        <svg
          width="58"
          height="40"
          viewBox="0 0 58 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto mb-16"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="
              M0 19.9012C0 25.0654 2.16494 30.4786 5.63954 33.9475C12.4395
              40.7375 22.3443 41.9439 30.5386 36.9097C31.2669 36.4635 32.4474
              35.5755 33.0198 35.0059C37.9199 30.1175 37.0824 28.8483 37.0824
              21.8386V13.3848C37.0824 10.685 37.9466 9.06822 39.1538
              7.54564C40.6884 5.6127 43.036 4.93326 45.6553 4.93326C52.0187
              4.93326 56.0256 13.3871 50.9518 18.7195C49.1655 20.5963 46.0785
              21.6211 43.2788 20.8834C42.3722 20.6434 42.37 20.4304 41.2809
              20.4304C39.0669 20.4304 37.7929 24.0541 40.7753 25.1641C45.4392
              26.8997 49.9763 25.6866 53.6758 22.693C61.9948 15.9636 57.8609 0
              45.1296 0C42.9848 0 40.5436 0.726534 39.1248 1.52707C35.9086
              3.3434 33.443 6.3482 32.6389 10.1423C32.1489 12.452 32.3583
              21.9418 32.3605 25.0071C32.3627 28.6846 31.6032 30.5055 28.5852
              32.4765C22.6339 36.3626 14.5688 36.1249 9.57741 31.0391C8.37912
              29.8193 7.18083 28.4268 6.41018 26.8324C3.96906 21.7781 4.43902
              15.8133 7.62406 11.1985C7.96484 10.7029 8.08957 10.6558 8.42144
              10.241C8.75999 9.81942 8.8179 9.70057 9.2255 9.28797C11.19
              7.29673 15.0432 4.93326 18.892 4.93326C20.9723 4.93326 21.7875
              4.83908 23.8656 5.38622C25.4225 5.79658 25.2175 6.16657 27.113
              6.16657C28.7144 6.16657 29.0374 4.57223 29.0374 3.35013C29.0374
              0.955258 22.743 0.00448478 19.9411 0.00448478C16.5801 0.00448478
              14.2236 0.791563 11.8671 1.73785C10.6866 2.21324 9.70882 2.8142
              8.70876 3.48916C8.18312 3.84346 7.80447 4.16187 7.32115
              4.55878C7.04274 4.7875 6.95587 4.91532 6.6886 5.15525C5.11612
              6.56571 3.77751 8.0569 2.73958 9.98312C2.37653 10.6581 2.13821
              10.9339 1.82416 11.703C0.824103 14.1472 0 16.5219 0 19.9012Z
            "
            fill="#382FE0"
          />
        </svg>

        <h1 class="text-5xl font-bold mb-1 leading-tight">Welcome!</h1>
        <div class="text-sm text-gray-600 mb-4">
          Sign in to your CredPal account.
        </div>
      </div>


      <div>
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon mr-3">
              !
            </div>
            <div class="text-xs font-normal">{{ getFormError(form) }}</div>
          </div>
        </template>
        <template v-else>
          <div class="h-16" />
        </template>

        <form-group
          v-model="form.data.email.value"
          label-style="grey"
          type="email"
          name="email"
          left-icon="mail-outline"
          :data="form.data.email"
          autocomplete="username"
        >
          Email address
        </form-group>

        <form-group
          v-model="form.data.password.value"
          label-style="grey"
          type="password"
          name="password"
          left-icon="lock-closed-outline"
          :data="form.data.password"
          autocomplete="current-password"
        >
          Password
        </form-group>

        <div class="text-right -mt-4 mb-10">
          <a
            href="#!"
            class="font-medium text-blue-500 text-xs"
            @click.prevent="openPasswordResetModal"
          >
            Forgot password?
          </a>
        </div>

        <!--
        <div class="flex flex-row items-center -mt-6 mb-10">
          <checkbox
            id="remember"
            class="mr-3"
            v-model="form.data.remember.value"
          />
          <label for="remember" class="text-xs">Keep me signed in</label>
        </div>
        -->

        <div class="text-center mb-6">
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="form.loading"
          >
            <sm-loader-white v-if="form.loading" />
            <span v-else>Sign In</span>
          </button>
        </div>

        <div class="text-center text-sm">
          <span class="mr-1 opacity-50">Don't have an account?</span>
          <router-link
            :to="{ name: 'register' }"
            class="font-medium underline"
          >
            Sign Up
          </router-link>
        </div>
      </div>
    </form>

    <modal
      ref="successModal"
      class-name="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
    >
      <!--
      <img
        :src="checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      >
      -->

      <div class="text-lg font-bold mb-4">
        Login successful!
      </div>
      <div class="text-sm mb-10">
        Where would you like to login to?
      </div>

      <router-link
        v-if="isCompanyStaff"
        class="btn btn-blue mr-2 mb-2"
        :to="{
          name: 'dashboard', params: { accountType: ENV_VUE_APP_CUSTOMER_PATH }
        }"
      >
        Login As Staff
      </router-link>

      <router-link
        v-if="isCompanyAdmin"
        class="btn btn-blue mr-2 mb-2"
        :to="{
          name: 'dashboard', params: { accountType: ENV_VUE_APP_CORPORATE_PATH }
        }"
      >
        Login As Admin
      </router-link>

      <router-link
        v-if="isCredpalAdmin"
        class="btn btn-blue mr-2 mb-2"
        :to="{
          name: 'dashboard', params: { accountType: ENV_VUE_APP_ADMIN_PATH }
        }"
      >
        Login As CredPal Admin
      </router-link>
    </modal>

    <modal
      ref="failedLogin"
      class-name="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
    >
      <!--
      <img
        :src="checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      -->

      <div class="text-lg font-bold mb-4">
        Having trouble logging in?
      </div>
      <div class="text-sm text-gray-500 mb-10">
        Maybe we can help out.
      </div>

      <div class="border border-blue-200 rounded mb-10">
        <a
          href="https://credpal.com/login"
          class="
            flex items-center p-4 cursor-pointer text-gray-500 hover:text-black
            border-b border-blue-200
          "
        >
          <span class="text-sm">Are you an Old customer ?</span>
          <ion-icon name="chevron-forward-outline" class="text-lg ml-auto" />
        </a>
        <router-link
          :to="{ name: 'register' }"
          class="
            flex items-center p-4 cursor-pointer text-gray-500
            hover:text-black border-b border-blue-200
          "
        >
          <span class="text-sm">Are you Trying to Signup fresh ?</span>
          <ion-icon name="chevron-forward-outline" class="text-lg ml-auto" />
        </router-link>
        <div
          class="
            flex items-center p-4 cursor-pointer text-gray-500 hover:text-black
          "
          @click="openPasswordResetModal"
        >
          <span class="text-sm">Did you forget your password ?</span>
          <ion-icon name="chevron-forward-outline" class="text-lg ml-auto" />
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="$refs.failedLogin.close"
        >
          Close
        </button>
      </div>
    </modal>

    <modal
      ref="passwordResetModal"
      class-name="w-full md:w-2/5 xl:w-3/10"
      @close="resetPasswordResetForm"
    >
      <template v-if="forgotPasswordForm.success">
        <h3 class="text-xl font-bold mb-3">Password Request Submitted.</h3>

        <p class="text-sm opacity-50 mb-10">
          Please check your mail for a link to reset your password.
          Don't forget to check your spam folder id you can't find it.
        </p>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-blue btn-md w-full"
            @click.prevent="$refs.passwordResetModal.close"
          >
            Okay
          </button>
        </div>
      </template>

      <template v-else>
        <div class="mb-16">
          <h3 class="text-xl font-bold mb-3">Did you forget your password?</h3>

          <p class="text-sm opacity-50">
            Enter your email address below to find your account.
          </p>
        </div>

        <form @submit.prevent="forgotPassword">
          <template v-if="getFormError(forgotPasswordForm)">
            <div class="alert alert-red-soft -mt-8 mb-12">
              <div class="alert-icon mr-3">
                !
              </div>
              <div class="text-xs font-normal">
                {{ getFormError(forgotPasswordForm) }}
              </div>
            </div>
          </template>

          <form-group
            v-model="forgotPasswordForm.data.email.value"
            type="email"
            left-icon="mail-outline"
            name="email"
            :form="forgotPasswordForm"
          >
            Email Address
          </form-group>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-blue btn-md w-full"
              :disabled="forgotPasswordForm.loading"
            >
              <sm-loader-white v-if="forgotPasswordForm.loading" />
              <span v-else>Find My Account</span>
            </button>
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        creditCards: require('@/assets/credit-cards.svg'),
        forgotPasswordForm: this.$options.basicForm([
          {
            name: 'email',
            rules: 'required|email'
          },
        ]),
        form: this.$options.basicForm([
          {
            name: 'email',
            // value: 'ayukpaul1@gmail.com',
            // value: 'paulayuk94@gmail.com',
            rules: 'required|email'
          },
          {
            name: 'password',
            // value: '700clubber',
            rules: 'required'
          },
          {name: 'remember', value: false, rules: 'nullable'}
        ]),
        shouldLogin: false
      }
    },
    computed: {
      redirect() {
        return this.$route.query.redirect;
      },
      sessionExpired() {
        return this.$route.query.expired;
      }
    },
    methods: {
      async forgotPassword() {
        if (!this.validateForm(this.forgotPasswordForm)) {
          return false;
        }

        this.forgotPasswordForm.error = false;
        this.forgotPasswordForm.loading = true;

        await this.$post({
          url: `${this.$baseurl}/password/request`,
          data: {
            email: this.forgotPasswordForm.data.email.value
          },
          success: () => {
            this.forgotPasswordForm.success = true;
          },
          error: error => {
            this.forgotPasswordForm.error = error;
            this.mapFormErrors(
              this.forgotPasswordForm,
              error?.response?.data?.errors
            );
          }
        });

        this.forgotPasswordForm.loading = false;
      },
      navigate() {
        // if (!user.company_id) {
        //   if (
        //     this.user.profile &&
        //     this.user.profile.employer &&
        //     this.user.profile.salary &&
        //     this.user.profile.bvn &&
        //     this.user.documents?.find(
        //       document => document.type == 'bank_statement'
        //     ) &&
        //     this.user.documents?.find(
        //       document => document.type == 'govt_id'
        //     ) &&
        //     this.user.documents?.find(
        //       document => document.type == 'work_id'
        //     )
        //   ) {
        //     console.log('good to go');
        //   }else {
        //     console.log('to register');
        //     return this.$router.push({ name: 'register' });
        //   }
        // }

        // console.log('well')

        this.$router.push({ name: 'root' });
      },
      openPasswordResetModal() {
        this.$refs.passwordResetModal.open();
        this.$refs.failedLogin.close();
      },
      resetPasswordResetForm() {
        setTimeout(() => {
          this.forgotPasswordForm = this.resetForm(this.forgotPasswordForm);
        }, 500);
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = false;
        this.form.loading = true;

        await this.$post({
          url: `${this.$baseurl}/login`,
          data: {
            email: this.form.data.email.value,
            password: this.form.data.password.value,
          },
          success: (response) => {

            this.$store.dispatch('session/login', {
              token: response.data.token,
              user: response.data.user,
              roles: response.data.roles,
              permissions: response.data.permissions?.map(
                permission => permission.slug
              ),
              corporate_card_count: response.data.corporate_card_count,
              keys: response.data.keys,
              remember: this.form.data.remember.value === true
            });

            window.dengage('setContactKey', response.data.user.email);
            const modals = response.data.closed_modals;
            if (modals && modals.length) {
              modals.forEach( modal => {
                this.$store.commit('modals/closeModal', { modal: modal.key });
              } );
            }

            this.$root.$emit('login');
            this.$root.$emit(
              'global',
              { event: 'loggedIn', params: [response.data.user] }
            );

            if (!this.user.change_password_at) {
              return this.$router.push({ name: 'update-password' });
            }

            if (this.redirect) {
              return this.$router.push({ path: this.redirect });
            }

            this.navigate(response.data.user);

          },
          error: (error) => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
            if (error?.response?.data?.errors) {
              this.$refs.failedLogin.open();
            }
          }
        });

        this.form.loading = false;

      }
    }
  }
</script>
